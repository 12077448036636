import * as THREE   from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'

mapboxgl.accessToken = 'pk.eyJ1IjoibWF0aGlzZnI2MyIsImEiOiJjbDBmZ3puZTcwMDV3M2RxdHZjemZ4dGgxIn0.vsdJgyXayYlDtYVGgKymtw'


//region Default
const x         = -0.00006
const y         = 0.000095
const mapOrigin = [2.4125 + x, 48.8244 + y]
const map       = new mapboxgl.Map({
	                                   style:     'mapbox://styles/mapbox/light-v11',
	                                   center:    mapOrigin,
	                                   zoom:      15.5,
	                                   pitch:     45,
	                                   bearing:   -17.6,
	                                   container: 'map-container',
	                                   antialias: true,
                                   })

const custom3dLayer = {
	'id':           'add-3d-buildings',
	'source':       'composite',
	'source-layer': 'building',
	'filter':       ['==', 'extrude', 'true'],
	'type':         'fill-extrusion',
	'minzoom':      15,
	'paint':        {
		'fill-extrusion-color': '#aaa',

		// Use an 'interpolate' expression to
		// add a smooth transition effect to
		// the buildings as the user zooms in.
		'fill-extrusion-height':  [
			'interpolate',
			['linear'],
			['zoom'],
			15,
			0,
			15.05,
			['get', 'height'],
		],
		'fill-extrusion-base':    [
			'interpolate',
			['linear'],
			['zoom'],
			15,
			0,
			15.05,
			['get', 'min_height'],
		],
		'fill-extrusion-opacity': 0.6,
	},
}

//endregion

class BoxCustomLayer {
	type          = 'custom'
	renderingMode = '3d'

	constructor(id) {
		this.id = id
	}

	async onAdd(map, gl) {
		this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6)
		// this.camera = new THREE.Camera();

		const centerLngLat = map.getCenter()
		this.center        = mapboxgl.MercatorCoordinate.fromLngLat(centerLngLat, 0)
		const {
			      x,
			      y,
			      z,
		      }            = this.center
		const s            = this.center.meterInMercatorCoordinateUnits()

		const scale    = new THREE.Matrix4().makeScale(s, s, -s)
		const rotation = new THREE.Matrix4().multiplyMatrices(
			new THREE.Matrix4().makeRotationX(-0.5 * Math.PI),
			new THREE.Matrix4().makeRotationY(Math.PI),
		)

		this.cameraTransform =
			new THREE.Matrix4().multiplyMatrices(scale, rotation)
			                   .setPosition(x, y, z)

		this.map   = map
		this.scene = this.makeScene()

		// use the Mapbox GL JS map canvas for three.js
		this.renderer = new THREE.WebGLRenderer({
			                                        canvas:    map.getCanvas(),
			                                        context:   gl,
			                                        antialias: true,
		                                        })

		this.renderer.autoClear = false

		this.raycaster      = new THREE.Raycaster()
		this.raycaster.near = -1
		this.raycaster.far  = 1e6
	}

	makeScene() {
		const scene = new THREE.Scene()

		//region Lights
		const ambientLight     = new THREE.AmbientLight(0xffffff) // soft white light
		ambientLight.intensity = 1
		scene.add(ambientLight)
		//endregion

		//region Model
		const modelGroup = new THREE.Group()
		modelGroup.name  = 'model'

		const loader = new GLTFLoader()
		loader.load(
			'/nexity.glb',
			(gltf) => {
				gltf.scene.rotation.y = Math.PI / 2 + Math.PI / 4 + Math.PI / 16 * .8
				modelGroup.add(gltf.scene)
			},
		)
		scene.add(modelGroup)
		//endregion

		return scene
	}

	render(gl, matrix) {
		this.camera.projectionMatrix = new THREE.Matrix4()
			.fromArray(matrix)
			.multiply(this.cameraTransform)
		this.renderer.state.reset()
		this.renderer.render(this.scene, this.camera)
	}

	raycast(point) {
		var mouse = new THREE.Vector2()
		// // scale mouse pixel position to a percentage of the screen's width and height
		mouse.x   =
			(
				point.x / this.map.transform.width
			) * 2 - 1
		mouse.y   =
			1 - (
				  point.y / this.map.transform.height
			  ) * 2

		const camInverseProjection = new THREE.Matrix4().getInverse(this.camera.projectionMatrix)
		const cameraPosition       = new THREE.Vector3().applyMatrix4(camInverseProjection)
		const mousePosition        = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection)
		const viewDirection        = mousePosition.clone()
		                                          .sub(cameraPosition)
		                                          .normalize()

		this.raycaster.set(cameraPosition, viewDirection)

		// calculate objects intersecting the picking ray
		var intersects = this.raycaster.intersectObjects(this.scene.children, true)
		if (intersects.length) {
			console.log(intersects[0].object)
			console.log('Model has been clicked')
		}
	}
}

let boxLayer = new BoxCustomLayer('box')

map.on('load', () => {
	// Insert the layer beneath any symbol layer.
	const layers       = map.getStyle().layers
	const labelLayerId = layers.find(
		(layer) => layer.type === 'symbol' && layer.layout['text-field'],
	).id

	// Custom 3D layer
	map.addLayer(
		custom3dLayer,
		labelLayerId,
	)

	map.addLayer(boxLayer)
})

map.on('click', e => {
	boxLayer.raycast(e.point)
})
